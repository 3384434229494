
    var riot = require('riot')
     require('riot-kit/lib/form');require('riot-kit/lib/i18n');
riot.tag2('change-password', '<form class="form-horizontal"> <div class="row"> <div class="col-xs-12"> <p riot-tag="i18n">Please enter a new password and confirm by retyping it.</p> <forminput name="password" label="Password" onupdate="{handleUpdate}" type="password" required width1="4" width2="8"></forminput> <forminput name="passwordConfirm" label="Retype Password" onupdate="{handleUpdate}" type="password" required min="8" width1="4" width2="8"></forminput> </div> </div> <div class="row"> <div class="col-xs-12"> <div if="{error}" class="alert alert-danger">{errorMessage.message}</div> <div if="{success}" class="alert alert-success">{successMessage}</div> </div> </div> <div class="row"> <div class="col-xs-12"> <div onclick="{submit}" class="btn btn-primary pull-right"> <i18n>Change Password</i18n> </div> </div> </div> </form>', '', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource(opts.src || '/duocms/api/users/me', true);

this.user = {};

this.handleUpdate = (function(_this) {
  return function(input) {
    return _this.user[input.name] = input.value;
  };
})(this);

this.submit = (function(_this) {
  return function() {
    if (_this.user.password === _this.user.passwordConfirm) {
      _this.error = false;
      _this.success = false;
      _this.update();
      return api.save(_this.user, function(user, err) {
        if (err != null) {
          _this.showError(err);
        }
        if (err == null) {
          _this.showSuccess('Your password has been successfully changed');
          return setTimeout(function() {
            if (opts.onsuccess != null) {
              return window.location.href = opts.onsuccess;
            }
          }, 2000);
        }
      });
    } else {
      return _this.showError({
        message: 'Passwords do not match'
      });
    }
  };
})(this);

this.showError = (function(_this) {
  return function(errorMessage) {
    _this.errorMessage = errorMessage;
    _this.error = true;
    return _this.update();
  };
})(this);

this.showSuccess = (function(_this) {
  return function(successMessage) {
    _this.successMessage = successMessage;
    _this.success = true;
    return _this.update();
  };
})(this);
});
    
  
window.riot = riot = require 'riot'
require 'riot-kit/lib/slideshow'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/auth'
require 'riot-kit/lib/raw'
require 'riot-kit/lib/pagination'
require 'riot-kit/lib/gmap'
require 'riot-kit/lib/confirm'
require 'riot-kit/lib/img_loader'
require 'riot-kit/lib/social-share'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/ecommerce'

require './tags/checkout.tag'

require './libs/slideshow.coffee'

require './tags/navigation.tag'
require './tags/artist.tag'
require './tags/artists-list.tag'
require './tags/artist-tags.tag'
require './tags/registration.tag'
require './tags/profile.tag'
require './tags/uploadimage.tag'
require './tags/change_password.tag'
require './tags/membership-renewal.tag'
require './tags/mypassword.tag'
require './tags/pricetag.tag'

$ = jQuery = window.jQuery = window.$ = require "jquery"
require 'owl.carousel'

setTimeout(=>
  $(document).ready ()=>
    $('.dl_partners.owl-carousel').owlCarousel
      items:6
      pagination:false
      nav:true
      margin: 0
      autoplay:true
      autoplayHoverPause:true
      loop:true
      responsive:{
        0 : {items:1}
        480:{items:2}
        768:{items:3}
        992 : {items:6}
      }
,1000)

LuminousGallery = require('luminous-lightbox').LuminousGallery
lg = new LuminousGallery(document.querySelectorAll(".lightbox"),{injectBaseStyles:true,showCloseButton:true,includeImgixJSClass:true})

riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow')

parallax = document.querySelector('.parallax')
navtrigger = document.querySelector('.navtrigger')

window.checkscroll = =>
  if parallax 
    wh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    prect = parallax.getBoundingClientRect()
    max = wh + prect.height
    percent = (prect.bottom / max) * 100
    if percent >= 0
      pimg = parallax.querySelector('.parallax-image')
      tx = ((100 - percent)/10)*2
      pimg.style.transform = 'translate3d(0,-'+tx+'%,0)'
  if navtrigger
    nttop = navtrigger.getBoundingClientRect().top
    if nttop < 100
      document.querySelector('.navbar-default').classList.add('opaque')
    else
      document.querySelector('.navbar-default').classList.remove('opaque')

['scroll','load','resize'].forEach (type)=>
  window.addEventListener type,checkscroll
  if type == "load" && parallax
    img = parallax.querySelector('img')?.getAttribute('src') || ""
    if img
      parallax.querySelector('.parallax-image').style.backgroundImage = "url('"+img+"')"


leaders = document.querySelectorAll('.dl_board .media')
if leaders.length > 0
  [].map.call leaders, (leader)=>
    if leader.querySelector('.jobdesc')
      leader.querySelector('h4').addEventListener 'click',(e)=>
        leader.classList.add('open')
      leader.querySelector('.close').addEventListener 'click',(e)=>
        leader.classList.remove('open')


# setTimeout ()=>
#   artistform = document.querySelector('.artist-filters')

#   if artistform
#     artistsearch = artistform.querySelector('input[type="text"]')
#     artistlist = document.querySelectorAll('.dl_artists .item')
#     artistsearch.addEventListener 'keyup',(e)->
#       filterArtists(artistsearch,artistlist)
# ,1000


# window.filterArtists = (artistsearch,artistlist)->
#   [].forEach.call artistlist,(artist)->
#     artist.classList.remove('hideme')
#     re = new RegExp(artistsearch.value,'i')
#     if artist.querySelector('h3 a') && !artist.querySelector('h3 a').innerText.match(re)
#       artist.classList.add('hideme')


window.setKeywordButtons = (objs,targetid)->
  selectedrads = []
  [].forEach.call objs, (rad)=>
    rad.addEventListener 'mouseup', (e)=>
      txt = document.querySelector('#'+targetid).value
      str = ","+rad.children[0].value
      if rad.className.indexOf("active")>-1
        rad.classList.remove('active')
        txt = txt.replace(str,'')
        selectedrads.splice(rad,1)
      else
        rad.classList.add('active')
        selectedrads.push(rad)
        txt = txt+str
      if selectedrads.length==0
        objs[0].className = objs[0].className.replace(/ ?active ?/,'')+ 'active'
      else
        objs[0].className = objs[0].className.replace(/ ?active ?/,' ')
      txt = txt.replace(/,,+/g,',')
      txt = txt.replace(/,$/,'')
      document.querySelector('#'+targetid).value = txt

subjectareas = document.querySelectorAll('.btn-satags')
businessareas = document.querySelectorAll('.btn-batags')
if subjectareas.length > 0
  setKeywordButtons(subjectareas,'subjectareatagshidden')
if businessareas.length > 0
  setKeywordButtons(businessareas,'businessareatagshidden')

ctacheck = document.querySelector('#ctacheck')
ctainput = document.querySelector('#ctainput')
if ctacheck
  ctacheck.addEventListener 'click',(e)=>
    if e.target.checked
      ctainput.value = true
    else
      ctainput.value = false


scrollbuttons = document.querySelectorAll('.btn-scroll-to-signup')
footer = document.querySelector('.footer')
if scrollbuttons && scrollbuttons.length > 0
  [].map.call scrollbuttons, (sb)=>
    sb.addEventListener 'click', (e)=>
      e.preventDefault()
      footer.scrollIntoView({ behavior: 'smooth'})
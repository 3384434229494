
    var riot = require('riot')
    
riot.tag2('membership-renewal', '<div if="{diff &amp;&amp; diff &lt; 31}" class="row"> <div class="col-xs-12"> <div class="renewal-notice {diff &gt; 15 ? \'alert-info\' : (diff &gt; 5 ? \'alert-warning\' : \'alert-danger\')}"> <div class="row"> <div class="col-xs-12 col-md-6"> <p><i class="fa fa-warning fa-lg"></i><span if="{diff &gt; 0}">Your Aeims membership expires in {diff} days.</span><span if="{diff &lt; 0}">Your Aeims mmembership expired {Math.abs(diff)} days ago.</span></p> </div> <div class="col-xs-12 col-md-6 text-right"> <buy id="renewbutton" productid="{product.id}" price="{product.price}" showprice="true" showqty="{false}" currency="EUR" btntext="Renew membership"></buy> </div> </div> </div> </div> </div> <div if="{!diff || diff &gt; 30}" class="row"> <div class="col-xs-12 col-md-6"><a href="/members/my_profile/" class="memberblock"> <h4> <u>My Personal Profile</u> </h4></a></div> <div class="col-xs-12 col-md-6"><a href="/artists/profile/?id={user.id}" class="memberblock"> <h4> <u>My Artist Profile</u> </h4></a></div> </div>', '.renewal-notice { border: 1px solid rgba(0,0,0,0.1); padding: 15px; } .renewal-notice i.fa { padding-right: 15px; } a.memberblock { display: block; }', '', function(opts) {
var basketApi, productApi, resource, selfApi;

resource = require('riot-kit/utils/apiResource.coffee');

selfApi = new resource('/duocms/api/users/me', true);

productApi = new resource('/duocms/api/products', true);

basketApi = new resource('/duocms/api/basket', true);

this.user = {};

this.product = {};

this.productid = 0;

this.on('mount', function() {
  basketApi.get((function(_this) {
    return function(basketdata) {
      if (basketdata.items.length > 0) {
        return basketdata.items.forEach(function(item) {
          return basketApi.remove({
            id: item.id
          }, function(basket) {
            return console.log("basket empty");
          });
        });
      }
    };
  })(this));
  this.renewbutton.addEventListener('click', (function(_this) {
    return function() {
      return window.location.href = "/checkout/";
    };
  })(this));
  return selfApi.get((function(_this) {
    return function(data) {
      if (data) {
        _this.user = data;
        if (_this.user.group_ids.indexOf(6) >= 0) {
          _this.productid = 4;
        }
        if (_this.user.group_ids.indexOf(7) >= 0) {
          _this.productid = 5;
        }
        if (_this.user.group_ids.indexOf(8) >= 0) {
          _this.productid = 6;
        }
        productApi.get({
          id: _this.productid
        }, function(productdata) {
          if (productdata) {
            _this.product = productdata;
            return _this.update();
          }
        });
        return _this.checkRenewalDate();
      }
    };
  })(this));
});

this.checkRenewalDate = (function(_this) {
  return function() {
    var date, now;
    date = _this.user.renewal_date;
    now = new Date();
    if (date) {
      _this.diff = Math.floor((Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())) / (1000 * 60 * 60 * 24));
      console.log("DIFF", _this.diff);
    }
    return _this.update();
  };
})(this);
});
    
  
addControls = ->
  Array.from(document.querySelectorAll(".duoslideshow")).forEach (slideshow)->
    next = document.createElement("div")
    next.className = "next fa fa-chevron-right"
    next.addEventListener "click",-> moveNext(slideshow)
    slideshow.appendChild(next)
    prev = document.createElement("div")
    prev.className = "prev fa fa-chevron-left"
    prev.addEventListener "click",-> movePrev(slideshow)
    slideshow.appendChild(prev)

addPagers = ->
  Array.from(document.querySelectorAll(".duoslideshow")).forEach (slideshow)->
    pager = document.createElement("div")
    pager.className = "pager"
    slides = Array.from(slideshow.querySelectorAll(".duoslideshow .duoslide"))
    slides.forEach (slide,idx)->
      pagerButton = document.createElement("div")
      pagerButton.className = "pager-button"
      pagerButton.addEventListener "click",-> gotoSlide(slideshow,idx)
      pager.appendChild(pagerButton)
    slideshow.appendChild(pager)
    pagerSetActive(slideshow)

pagerSetActive = (slideshow)->
  slides = Array.from(slideshow.querySelectorAll(".duoslide"))
  pagerButtons = Array.from(slideshow.querySelectorAll(".pager-button"))
  pagerButtons.forEach (b,idx)-> 
    b.classList.add("active") if slides[idx].matches(".active") && !b.matches(".active")
    b.classList.remove("active") if !slides[idx].matches(".active") && b.matches(".active")

nextSlide = (active)->
  newactive = active.nextElementSibling
  return newactive if newactive && newactive.matches(".duoslide")
  return nextSlide(newactive) if newactive

prevSlide = (active)->
  newactive = active.previousElementSibling
  return newactive if newactive && newactive.matches(".duoslide")
  return prevSlide(newactive) if newactive

preventDoubleAnimation = (slideshow)->
  return true if slideshow.matches(".active")
  slideshow.classList.add("active")
  setTimeout => 
    slideshow.classList.remove("active")
  ,1000
  return false

gotoSlide = (slideshow,idx)->
  return if preventDoubleAnimation(slideshow)
  slides = Array.from(slideshow.querySelectorAll(".duoslide"))
  active = slideshow.querySelector(".duoslide.active")
  active.classList.remove("active")
  slides[idx].classList.add("active")
  pagerSetActive(slideshow)
  
moveNext = (slideshow)->
  return if preventDoubleAnimation(slideshow)
  slides = Array.from(slideshow.querySelectorAll(".duoslideshow .duoslide"))
  if slides?.length
    active = slideshow.querySelector(".duoslideshow .duoslide.active") # get current active
    if active 
      newactive = nextSlide(active)
      active.classList.remove("active") # remove active class
    if newactive
      newactive.classList.add("active")
    else
      slides[0].classList.add("active")
  pagerSetActive(slideshow)

movePrev = (slideshow)->
  return if preventDoubleAnimation(slideshow)
  slides = Array.from(slideshow.querySelectorAll(".duoslideshow .duoslide"))
  if slides?.length
    active = slideshow.querySelector(".duoslideshow .duoslide.active") # get current active
    if active 
      newactive = prevSlide(active)
      active.classList.remove("active") # remove active class
    if newactive
      newactive.classList.add("active")
    else
      slides[slides.length-1].classList.add("active")
  pagerSetActive(slideshow)

addExtraSlides = ->
  return if !document.querySelector(".banner .duoslideshow")
  slideshow = document.querySelector(".banner .duoslideshow")
  return if !window.extraslides
  newslides = JSON.parse(window.extraslides.replace(/(&quot\;)/g,"\""))
  return if newslides.length < 1
  newslides = shuffle(newslides)
  console.log newslides
  slides = Array.from(slideshow.querySelectorAll(".duoslideshow .duoslide"))
  max = 2
  count = 0
  while count < max
    newslide = document.createElement 'div'
    newslide.classList.add 'duoslide'
    newslide.innerHTML = '<figure><div><img src="'+newslides[count]+'" /></div><div class="container"><figcaption><h2>WELCOME TO AEIMS</h2><h3>ASSOCIATION EUROPÉENNE DES ILLUSTRATEURS MÉDICAUX ET SCIENTIFIQUES</h3><p>&nbsp;</p><p><a class="btn btn-default" href="/membership/">JOIN AEIMS</a></p></figcaption></div></figure>'
    if count == 0
      slides[0].parentNode.insertBefore(newslide,slides[0])
    else
      slides[slides.length-1].parentNode.insertBefore(newslide,slides[slides.length-1].nextSibling)
    count++

shuffle = (array) ->
  currentIndex = array.length
  temporaryValue = undefined
  randomIndex = undefined
  # While there remain elements to shuffle...
  while 0 != currentIndex
    # Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    # And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  array

if !window.location.search?.match (/editmode/)
  setTimeout(=>
    addExtraSlides()
    addControls()
    addPagers()
    setInterval ->
      Array.from(document.querySelectorAll(".duoslideshow")).forEach (slideshow)->
        moveNext(slideshow)
    ,10000
  ,100)